import React from "react";
import "./About.css"

function About() {
  return (
    <section className="about" id="about">
      <h1 className="section__title section__title_about">ReDent</h1>
      <div className="about__img"></div>
      <h2 className="section__subtitle">С заботой о Вашей улыбке</h2>
      <p className="section__text">
        Стоматологическая клиника Redent – это качественное и доступное
        лечение зубов. Комфорт, безопасность и отсутствие боли – это не
        просто часть нашей работы, это наша идеология. В нашей
        стоматологической клинике вы получите целый спектр услуг от
        высококвалифицированных специалистов: стоматолога - терапевта,
        ортопеда, имплантолога и хирурга. Стоматологическая клиника Redent,
        здоровье Ваших зубов!
      </p>
      <p className="section__text">
        Наши врачи - стоматологи регулярно проходят обучение на базе
        новейших технологий. Стоматологическая клиника Redent идет в ногу с
        мировыми тенденциями и применяет новейшие методики и технологии.
        Опытные врачи - стоматологи подбирают наиболее подходящий метод
        лечения, имплантации или протезирования, как с точки зрения
        эффективности и надежности, так и исходя из финансовой возможности
        пациента.
      </p>
      <p className="section__text">
        Мы установили действительно привлекательные цены, чтобы абсолютно
        все смогли получить качественное лечение, протезирование и
        имплантацию недорого! Стоматологическую клинику Redent пациенты
        рекомендуют своим друзьям и родственникам. Большая часть пациентов
        приходят к нам именно по рекомендации близких и знакомых, что
        собственно и является основным показателем доверия.
      </p>
    </section>
  )
}

export default About;