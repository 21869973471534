function Contacts() {
   return (
      <section className="contacts content__section" id="contacts">
         <h2 className="section__title">Контакты</h2>
         <p className="section__text"> ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "РЕДЕНТ"</p>
         <p className="section__text">ИНН 5501270102</p>
         <p className="section__text">КПП	550101001</p>
         <p className="section__text">ОГРН 1215500006743</p>
         <p className="section__text">Юридический адрес: Россия, 644077, г. Омск, ул. Пригородная, дом 23, кор. 2, пом. 10</p>
         <p className="section__text">Фактический адрес: Россия, 644077, г. Омск, ул. Пригородная, дом 23, кор. 2, пом. 10</p>
         <p className="section__text">Номер лицензии: Л041-01165-55/00152933</p>
         <p className="section__text"></p>
      </section>
   )
}
export default Contacts